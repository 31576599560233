import loadable from "@loadable/component";
import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import i18n from "i18next";
import { inject } from "mobx-react";
import { useEffect, useMemo } from "react";
import type { Store } from "@inferno/renderer-shared-core";
import { PayloadResolverLegacy } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { Heading } from "../../ui";
import { Byline } from "../../components/Byline.component";
import "./FrontMatter.style.scss";

const EyebrowLoader = loadable(() => import("./EyebrowLoader.component"));
const Eyebrow = loadable(() => import("../../components/Eyebrow.component"));

const log = ILog.logger("FrontMatter");

interface FrontMatterProps {
  content: Webapi.PublishRecordFragment;
  block: Webapi.Block_SitesItemBlock_Fragment;
  eyebrow?: Webapi.Eyebrow;
  store?: Store;
}

export const FrontMatter = inject("store")(({ content, block, store }: FrontMatterProps) => {
  if (!store || !content || !block) {
    return null;
  }
  const resolvedPayload = PayloadResolverLegacy.decode(content.payload);
  if (isLeft(resolvedPayload)) {
    const errorMessage = `Payload did not validate! ${getPaths(resolvedPayload)}`;
    log.error(errorMessage);
    return null;
  }
  const payload = resolvedPayload.right;
  const eyebrow = useMemo(() => {
    if (payload.is_sponsored) {
      return { topic_name: i18n.t("sponsored_content") };
    } else if (payload.feed_vendor && payload.feed_partner_content) {
      return { topic_name: i18n.t("partner_content") };
    }
    return null;
  }, [payload.feed_vendor, payload.is_sponsored, payload.feed_partner_content]);

  useEffect(() => {
    if (eyebrow) {
      store.storeBlock({
        ...block,
        type: "item",
        eyebrow,
      });
    }
  }, [block, eyebrow, store]);

  const headerCss = classnames("content-header", {
    "front-matter": block.tags?.includes("display-hints/front-matter"),
  });
  const { site, request } = store;
  const url = site.getShareUrl(request);

  return (
    <header className={headerCss}>
      {eyebrow ? <Eyebrow {...eyebrow} /> : <EyebrowLoader content={content} block={block} />}
      <Heading level={1}>{content.summary.title}</Heading>
      <Byline
        publish_date={content.pub_start}
        user={content.summary.author}
        image={content.summary.image}
        feed_vendor={payload.feed_vendor || ""}
        url={url}
        is_sponsored={payload.is_sponsored}
        title={content.summary.title}
        description={content.summary.description}
      />
    </header>
  );
});

export default FrontMatter;
