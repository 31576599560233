import { format } from "date-fns";
import { inject } from "mobx-react";
import { Translation } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";

import { getDateFormat } from "@ihr-radioedit/inferno-core";
import "./Byline.style.scss";
import { ShareButtons } from "./integrations/ShareButtons.component";

export interface BylineProps {
  publish_date?: number;
  publish_updated?: number;
  user?: string;
  feed_vendor?: string;
  is_sponsored?: boolean;
  show_updated_timestamp?: boolean;
  image?: string | null;
  store?: Store;
  url: string;
  title: string;
  description: string;
}

const getDateDisplay = (displayDate: number, dateFormat: string) => {
  if (displayDate) {
    const formatted = format(new Date(displayDate), dateFormat, { useAdditionalWeekYearTokens: true });
    return (
      <time className="card-date" dateTime={`${displayDate}`}>
        {formatted}
      </time>
    );
  }
  return null;
};

const getUser = (props: BylineProps) => {
  if (props.user) {
    if (props.feed_vendor) {
      return props.user + ", " + props.feed_vendor;
    } else {
      return props.user;
    }
  } else if (props.feed_vendor) {
    return props.feed_vendor;
  }
  return null;
};

export const User = (props: BylineProps) => {
  const hasAuthor = props.user || props.feed_vendor;
  return props.is_sponsored ? (
    <Translation>
      {t => (
        <span className="author-block">
          {hasAuthor ? t("posted_sponsored") : t("posted_sponsored_no_user")}
          <span className="author"> {getUser(props)}</span>
        </span>
      )}
    </Translation>
  ) : hasAuthor ? (
    <Translation>
      {t => (
        <span className="author-block">
          {t("posted")}
          <span className="author"> {getUser(props)}</span>
        </span>
      )}
    </Translation>
  ) : null;
};

export const PubUpdated = (props: BylineProps) => {
  if (!props.store) {
    return null;
  }
  if (props.publish_updated && props.show_updated_timestamp) {
    return (
      <div>
        <Translation>{t => <span>{t("updated")}</span>}</Translation>{" "}
        {getDateDisplay(props.publish_updated, getDateFormat(props.store.site, "pubUpdated"))}
      </div>
    );
  }
  return null;
};

export const PubDate = (props: BylineProps) => {
  if (!props.store) {
    return null;
  }
  if (props.publish_date) {
    return getDateDisplay(props.publish_date, getDateFormat(props.store.site, "pubDate"));
  }
  return null;
};

export const Byline = inject("store")((props: BylineProps) => {
  if (!props.store) {
    return null;
  }
  return (
    <figure className="component-byline-display">
      <figcaption>
        <User {...props} />
        <div className="date-block">
          <PubDate {...props} />
          <PubUpdated {...props} />
        </div>
        <ShareButtons url={props.url} title={props.title} desc={props.description} setStyles={false} />
      </figcaption>
    </figure>
  );
});
